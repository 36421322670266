/* -----------------------------------------------

Table of Content

	1. Body and Core Css
  2. NavBar Section
  3. Banner Section 
     - 3.1 Content
     - 3.2 Point
  4. About Section
  5. Work section
  6. Clients section
  7. Price section
  8. Footer

----------------------------------- */

/* -----------------------------------
		      1. Body and Core Css
----------------------------------- */
* {
  margin: 0;
  padding: 0;
  font-family: inherit;
  outline: none !important;
  list-style: none !important;
  text-decoration: none !important;
  scroll-behavior: smooth;
}

*:after,
*:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  font-style: normal;
  text-align: center;
  font-family: "Jost", sans-serif;
  color: #fff;
  background-color: #0d0e05;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #fff;
}

a,
a:hover {
  color: #fff;
  outline: none;
  text-decoration: none;
}

p {
  color: #92939e;
  font-size: 14px;
  line-height: 1.7;
  font-weight: 500;
}

img,
video {
  max-width: 100%;
}

.d-flex-center {
  display: flex;
  justify-content: center;
}

dl,
ol,
ul {
  padding: 0;
}

.Toastify__toast-body {
  font-size: 15px;
  font-weight: 600;
  font-family: "Jost", sans-serif;
}

/* Button */
.btn-st {
  position: relative;
  overflow: hidden;
  font-size: 14px;
  background: #fdcb02;
  color: #000;
  font-weight: 700;
  padding: 14px 23px;
  border-radius: 6px;
  border: none;
  z-index: 2;
  transition: all 0.3s;
}

.btn-st span {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.btn-st span img {
  max-width: 30px;
}

.btn-st::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background: linear-gradient(82.3deg, #fac902 10.8%, #b9ad1d 94.3%);
  transition: all 0.475s;
  z-index: 0;
  opacity: 0;
}

.btn-st:hover {
  background: #fdcb02;
  color: #ffffff;
}

.btn-st:hover::after {
  opacity: 1;
}

.btn2 {
  padding: 14px 40px;
}

.text-left {
  text-align: left;
}

/* -----------------------------------
                2. NavBar Section
  ----------------------------------- */
.navbar-custom {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100px;
  top: 0;
  left: 0;
  transition: all 0.3s;
  z-index: 999;
}

.navbar-custom .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar-custom .container .left-side {
  display: flex;
  align-items: center;
}

.navbar-custom .container .left-side .logo img {
  width: 100px;
}

.navbar-custom .container .info {
  display: flex;
  flex-direction: column;
}

.navbar-custom.fixed {
  position: fixed;
  height: 80px;
  background-color: #000;
  box-shadow: 0 0 20px 0 rgb(0 0 0 / 10%);
}

.dropdown-toggle {
  border-radius: 5px;
  padding: 7px 15px;
  background: #26271e;
}

.dropdown-menu {
  padding: 5px;
  background: #26271e;
}

.dropdown-item {
  display: flex;
  align-items: center;
  gap: 7px;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
}

.dropdown-item:hover {
  color: #fff;
  background-color: #000;
}

.dropdown-item img {
  max-width: 15px;
}

/* About */
.about {
  position: relative;
  min-height: 1020px;
  padding-top: 160px;
  overflow: hidden;
}

.about .bg {
  background-position: center 250px;
  position: absolute;
  bottom: 0;
  background-repeat: no-repeat;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-size: 100%;
  background-image: url(../assets/img/about-bg.png);
  z-index: -1;
}

.about .content .title {
  font-size: 40px;
  font-weight: 600;
  line-height: 75px;
  letter-spacing: -2.4px;
  text-align: left;
}

.about .content .title span {
  background: linear-gradient(
    270deg,
    #f5c704,
    #c8b316 17.47%,
    #f5d759 39.54%,
    #ad9423 60.49%,
    #ffef82 84.44%,
    #f2c605
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.about .content p {
  font-size: 16px;
  text-align: left;
}

.about .btn-block {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
}

.about .btn-block .btn-st {
  width: 49%;
  font-size: 16px;
  padding: 20px;
}

.about .wallet {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px 0;
  justify-content: space-between;
}

.about .wallet p {
  display: flex;
  flex-direction: column;
  color: #fff;
  font-size: 19px;
}

.about .wallet p span {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  background: linear-gradient(
    270deg,
    #f5c704,
    #c8b316 17.47%,
    #f5d759 39.54%,
    #ad9423 60.49%,
    #ffef82 84.44%,
    #f2c605
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

.about .content .text-time {
  color: #b3b3c0;
  font-size: 16px;
  font-weight: 600;
  font-style: italic;
  text-align: left;
}

.about .block-progress {
  max-width: 672px;
  margin-top: 50px;
}

.about .block-progress .progress-title {
  display: flex;
  margin-bottom: 22px;
  justify-content: space-between;
}

.about .block-progress .progress-title span {
  color: var(--color-white);
  font-weight: 600;
  font-size: 14px;
}

.about .block-progress .progress-title span span {
  color: #b3b3c0;
}

.about .block-progress .progress {
  overflow: unset;
  height: 6px;
  border-radius: 60px;
  -webkit-border-radius: 60px;
  -moz-border-radius: 60px;
  -ms-border-radius: 60px;
  -o-border-radius: 60px;
  background: hsla(0, 0%, 100%, 0.1);
}

.about .block-progress .progress-bar {
  border-radius: 1px;
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  -ms-border-radius: 1px;
  -o-border-radius: 1px;
  background: var(
    --gardient,
    linear-gradient(
      270deg,
      #f5c704,
      #c8b316 17.47%,
      #f5d759 39.54%,
      #ad9423 60.49%,
      #ffef82 84.44%,
      #f2c605
    )
  );
  height: 8px;
  top: -1px;
  position: relative;
}

.about .explore-wrap {
  transform: translate(27%, 50%);
}

.about .explore {
  margin-bottom: 180px;
}

.about .explore .scroll-down {
  width: 22px;
  height: 33px;
  border: 1px solid #fff;
  border-radius: 11px;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
}

.about .explore .scroll-down:after,
.about .explore .scroll-down:before {
  content: "";
  position: absolute;
  left: 50%;
  width: 2px;
  transform: translateX(-50%);
  background: #fff;
}

.about .explore .scroll-down:before {
  top: 18px;
  height: 2px;
  border-radius: 100%;
  animation: scrollDown 1.5s ease infinite;
}

.about .explore .scroll-down:after {
  top: 8px;
  height: 8px;
  border-radius: 3px;
}

.about .explore span {
  color: #fff;
  font-size: 20px;
  margin-top: 13px;
  display: inline-block;
}

.about .countdown h6 {
  color: #92939e;
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 40px;
}

.about .countdown .countdown-coin {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.about .countdown .countdown-coin .single {
  min-width: 100px;
  height: 100px;
  background-color: #343014;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 10px;
}

.about .countdown .countdown-coin .single h4 {
  font-size: 20px;
}

.about .countdown .countdown-coin .single p {
  margin-bottom: 0;
}

.about .countdown .countdown-coin .single:not(:last-child) {
  margin-right: 10px;
}

.about .shape .shape {
  position: absolute;
  z-index: -2;
}

.about .shape .shape--1 {
  top: 0;
  left: 0;
}

.about .shape .shape--2 {
  bottom: -100px;
  right: 0;
}

.about .coins .coin {
  position: absolute;
}

.about .coins .coin--1 {
  left: 1%;
  bottom: 15.5%;
}

.about .coins .coin--2 {
  left: 26%;
  bottom: 17.7%;
}

.about .coins .coin--3 {
  right: 43%;
  bottom: 16%;
}

.about .coins .coin--4 {
  right: 3%;
  bottom: 36%;
}
/* Partners */
.partners {
  position: relative;
  padding-top: 50px;
  padding-bottom: 100px;
}

.partners .subtitle {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.02em;
  background: linear-gradient(
    270deg,
    #f5c704,
    #c8b316 17.47%,
    #f5d759 39.54%,
    #ad9423 60.49%,
    #ffef82 84.44%,
    #f2c605
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  margin-top: -0.4em;
  position: relative;
  top: -6px;
  margin-bottom: 0;
  text-transform: uppercase;
}

.partners .slider {
  padding: 50px 0;
}

.partners .slider img {
  opacity: 0.5;
}

/* CTA */
.cta {
  position: relative;
  padding: 50px 0 100px;
}

.cta .box {
  background: linear-gradient(180deg, #0d0e05, #4e4d16);
  border-radius: 25px;
  display: flex;
  justify-content: space-between;
  padding-right: 130px;
}

.cta .box .details {
  padding: 75px 100px;
  max-width: 740px;
  text-align: left;
}

.cta .box .details h4 {
  color: #fff;
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 0;
  letter-spacing: -1.5px;
}

.cta .box .details p {
  color: #fff;
  font-size: 16px;
  margin-top: 30px;
  line-height: 1.667;
  margin-bottom: 0;
}

.cta .box .details .btn-block {
  display: block;
  margin-top: 40px;
}

.cta .box .images {
  margin-top: -90px;
}

.cta .box .images img {
  max-width: 80%;
  animation-name: alltuchtopdown;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

/* Footer */
.footer {
  position: relative;
  padding: 50px 0;
}

.footer .logo {
  max-width: 90px;
  margin-bottom: 30px;
}

.footer p {
  margin-bottom: 30px;
}

.footer .social {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 30px;
}

.footer .social a {
  height: var(--icon-size, 48px);
  width: var(--icon-size, 48px);
  line-height: 48px;
  display: inline-block;
  text-align: center;
  font-size: 16px;
  margin: 0 5px;
  border-radius: 10px;
  background: #ffffff14;
  transition: all 0.3s;
}

.footer .social a:hover {
  background: #fdcb02;
}

.footer .social a img {
  max-width: 20px;
}

.footer .copy {
  margin: 30px 0 0;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
}

.toast {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 99;
  font-weight: 700;
  color: #d94c48;
  border: 1px solid #d94c48;
  background: rgb(44 17 30);
}

.toast-header {
  background: transparent;
  border: none;
  color: #d94c48;
  font-weight: 300;
}

.toast-header .btn-close {
  filter: invert(1);
}

/* Responsive */
@media screen and (max-width: 1400px) {
  .banner .content h1 {
    font-size: 160px;
  }

  .banner .content .btn-wrap h3 {
    font-size: 35px;
  }

  .banner .hero-bg-gradient1 {
    right: -40%;
  }
}

@media screen and (max-width: 1450px) {
  .about .explore-wrap {
    transform: translate(9%, 50%);
  }
}

@media screen and (max-width: 1200px) {
  .about {
    min-height: 940px;
    padding-top: 120px;
  }

  .about .content .title {
    font-size: 35px;
    line-height: normal;
    letter-spacing: normal;
  }

  .about .explore {
    margin-bottom: 140px;
  }

  .about .explore-wrap {
    transform: translate(18%, 50%);
  }

  .partners .slider {
    padding: 50px 0 0;
  }

  .slick-slide {
    margin: 0 15px;
  }

  .cta {
    position: relative;
    padding: 0 0 100px;
  }

  .cta .box {
    padding-right: 50px;
  }

  .cta .box .details {
    padding: 50px;
  }

  .cta .box .images {
    margin-top: 0;
  }

  .about .btn-block .btn-st {
    width: 48%;
  }
}

@media screen and (max-width: 992px) {
  .navbar-custom .container {
    justify-content: center;
  }

  .about .content .title,
  .about .content p {
    text-align: center;
  }

  .about .bg {
    opacity: 0.2;
  }

  .about .d-flex {
    justify-content: center;
  }

  .about .coins .coin--4 {
    bottom: 56%;
  }

  .about .coins .coin--1 {
    bottom: 40.5%;
  }

  .about .coins .coin--2 {
    bottom: 39.7%;
  }

  .about .coins .coin--3 {
    right: 41%;
    bottom: 39%;
  }

  .about .explore-wrap {
    transform: none;
    margin-top: 170px;
  }

  .about .explore {
    margin-bottom: 50px;
  }

  .cta .box .details h4 {
    font-size: 30px;
  }

  .cta .box .details p {
    font-size: 14px;
  }

  .cta .box .images {
    margin: auto;
  }
}

@media screen and (max-width: 767px) {
  .about .coins {
    display: none;
  }

  .banner {
    padding: 200px 0 130px;
  }

  .cta .box {
    flex-direction: column;
    padding-right: 0;
  }

  .cta .box .images img {
    max-width: 50%;
  }

  .about .btn-block {
    display: flex;
    flex-direction: column;
  }

  .about .explore-wrap {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .footer .text-left {
    text-align: center;
  }

  .footer .social {
    justify-content: center;
  }

  .about .wallet {
    flex-direction: column;
  }

  .about .btn-block .btn-st {
    width: 100%;
  }
}

/* Animation */
@keyframes alltuchtopdown {
  0% {
    transform: rotateX(0) translateY(0);
  }
  50% {
    transform: rotateX(0) translateY(-30px);
  }
  100% {
    transform: rotateX(0) translateY(0);
  }
}

@keyframes ripple2 {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  10% {
    opacity: 0.6;
  }
  30% {
    opacity: 0.4;
  }
  40% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.14;
  }
  100% {
    transform: scale(3.5);
    opacity: 0;
  }
}

@keyframes leftToRight {
  0% {
    transform: rotateX(0) translate(0);
  }
  50% {
    transform: rotateX(0) translate(50px);
  }
  100% {
    transform: rotateX(0) translate(0);
  }
}

@keyframes scrollDown {
  0% {
    transform: translate(-50%);
    opacity: 0;
  }

  50% {
    transform: translate(-50%, 4px);
    opacity: 1;
  }

  to {
    transform: translate(-50%, 7px);
    opacity: 0;
  }
}
